/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { InitialiseColorScheme } from './services/Theme/Init';

const initialPathname = window.__remixContext.url;
const hydratedPathname = window.location.pathname;

/**
 * Fix initial pathname to match browser if we're dealing with trailing slashes.
 */
if (
  initialPathname !== hydratedPathname &&
  initialPathname.replace(/\/+$/, '') === hydratedPathname.replace(/\/+$/, '')
) {
  window.__remixContext.url = hydratedPathname;
}

startTransition(() => {
  InitialiseColorScheme();
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
